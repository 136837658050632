@use 'src/styles/config' as *;

.button {
  position: absolute;
  top: 25%;
  z-index: 10;
  color: #fff;
  opacity: 0.2;
  transition: 0.3s;
  &:hover {
    opacity: 1;
  }
  &Next {
    right: 0;
  }
  &Prev {
    left: 0;
  }
}

.dots {
  text-align: center;
  list-style: none;
  margin: 2rem 0 0;
  padding: 0;
  li {
    display: inline-block;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.2;
    margin: 0 0.7rem;
    transition: 0.3s;
    &:global {
      &.slick-active {
        opacity: 1;
      }
    }
  }
  button {
    display: none;
  }
}
