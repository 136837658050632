@use 'src/styles/config' as *;

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-grey-light;
  min-height: 4.8rem;
  position: relative;
}
.close {
  flex-grow: 0;
  margin: 0 1rem;
}
