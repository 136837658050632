@use 'src/styles/config' as *;

.wrap {
  position: relative;
  flex-grow: 1;
  min-height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem 5rem;
}
.right {
  display: flex;
  align-items: center;
  padding: 10rem 5rem;
  min-width: 0;
  background: radial-gradient(146.83% 156.74% at 50% 50%, #2065d1 0%, #142570 100%);
}

.slider {
  min-width: 0;
  max-width: 69rem;
  margin: 0 auto;
}

.paper {
  display: grid;
  align-items: center;
  min-height: 28.5rem;
  padding: 1rem 1.6rem;
  max-width: 50rem;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .left,
  .right {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .wrap {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  .left {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }
  .right {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .left {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }
  .right {
    padding-bottom: 10rem;
    padding-left: 0;
    padding-right: 0;
  }
}
