@use 'src/styles/config' as *;

.root {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 2rem;
  align-items: center;
}

.close {
  flex-grow: 0;
  margin: -0.6rem -1.6rem -0.6rem 0;
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .root {
    padding: 1rem 1.6rem;
  }
  .close {
    margin-right: -1rem;
  }
}
