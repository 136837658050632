@use 'src/styles/config' as *;

.main {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.bgL1 {
  background: #eeeef4;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  top: 17.22%;
  left: 3.3125%;
}

.bgL2 {
  background: #eeeef4;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  top: 21.22%;
  left: 1.9375%;
}

.bgL3 {
  background: url('../../../../images/bg_index_l_t.svg') no-repeat;
  width: 116px;
  height: 63px;
  position: absolute;
  top: 22.11%;
  left: 11.9375%;
}

.bgL4 {
  background: url('../../../../images/bg_index_l.png') no-repeat;
  width: 500px;
  height: 328px;
  position: absolute;
  top: 33.22%;
  left: -8.375%;
}

.bgR1 {
  background: #eeeef4;
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 50%;
  top: 32.44%;
  right: 14.5%;
}

.bgR2 {
  background: #eeeef4;
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  top: 30%;
  right: 13.125%;
}

.bgR3 {
  background: url('../../../../images/bg_index_r_t.svg') no-repeat;
  width: 253px;
  height: 152px;
  position: absolute;
  top: 13.11%;
  right: 16.8125%;
}

.bgR4 {
  background: url('../../../../images/bg_index_r.png') no-repeat;
  width: 461px;
  height: 405px;
  position: absolute;
  top: 29.11%;
  right: -6.8125%;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .main {
    position: relative;
    z-index: 1;
    &::before,
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
    &::before {
      background: url('../../../../images/bg_index_r.png') no-repeat 100% 50%;
      right: -10rem;
    }
    &::after {
      background: url('../../../../images/bg_index_l.png') no-repeat 0% 50%;
      left: -10rem;
    }
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .main {
    &::after {
      transform: scale(0.75);
      left: -20rem;
    }
    &::before {
      transform: scale(0.75);
      right: -20rem;
    }
  }
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .main {
    &::after {
      display: none;
    }
    &::before {
      transform: scale(1);
      right: 0;
      background-position: 50% 50%;
    }
  }
}
