@use 'src/styles/config' as *;
.root {
}
.paper {
  width: $panel-width;
  transition:
    width 0.25s ease-in-out,
    box-shadow 0.25s ease-in-out,
    background 0.25s ease-in-out;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  background: $color-nav-bg;
  &Open {
    width: $panel-width-open;
    z-index: 1201;
    box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.05);
  }
}
.inner {
  display: grid;
  grid-template-rows: auto auto 1fr;
  position: relative;
  height: 100%;
  overflow: hidden;

  & > * {
    min-width: 0;
  }
}
