@use 'src/styles/config' as *;

@mixin scrollbar {
  &::-webkit-scrollbar {
    background-color: rgba(gray, 0.2);
  }
  &::-webkit-scrollbar-track {
    background-color: rgba($color-grey, 0.1);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-secondary;
    &:hover {
      background-color: $color-secondary;
    }
  }
}

.root {
  scrollbar-color: $color-secondary $color-grey;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    background-color: transparent;
    border-radius: 0.6rem;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.4rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.7rem;
    background-color: transparent;
  }

  &.all,
  &:hover {
    @include scrollbar;
  }
  &.small {
    &::-webkit-scrollbar {
      width: 0.2rem;
      height: 0.2rem;
    }
  }
}
