@use 'src/styles/config' as *;

.root {
  padding: 1.7rem;
  display: flex;
  flex-wrap: wrap;

  > * {
    margin: 0.3rem;
  }
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .root {
    padding: 0.6rem 1.3rem;
  }
}
