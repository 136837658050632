@use 'src/styles/config' as *;

.root {
  display: grid;
  align-items: center;
}
.content {
  padding: 0 2rem;
}

@media (max-width: map-get($breakpoints, 'sm') - 1 + px) {
  .root {
    padding: 0 1.6rem;
  }
}
