@use 'src/styles/config' as *;

.footer {
  padding: 3rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem 1rem;
}

@media (max-width: map-get($breakpoints, 'xl') - 1 + px) {
  .footer {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .footer {
    flex-direction: column;
    gap: 1rem;
    padding: 1.6rem 1.6rem;
  }
  .links {
    justify-content: center;
  }
}
