@use 'src/styles/config' as *;
.item {
  padding: 0;
  margin: 0 1.5rem 0.5rem;
  position: relative;
  min-height: 3.8rem;
  border-radius: 4px;
}
.itemOpen {
  margin: 0;
  border-radius: 0;
  min-height: 4.4rem;
  padding: 0 1rem 0 3.3rem;
  position: relative;
  width: 100%;
}
.itemSelected {
  background-color: rgba($color-menu-active, 0.08) !important;
}
.itemLong {
  display: grid;
  grid-template-columns: 2.4rem 1fr auto;
  align-items: center;
  width: 100%;
  grid-gap: 1.2rem;
  &Active {
    color: $color-menu-active;
  }
}
.itemText {
  width: 17rem;
  &Inner {
    width: auto;
  }
}
.itemTextArrow {
  width: 14rem;
}
.itemSmall {
  width: 100%;
  color: $color-text-secondary;
  &.active {
    color: $color-menu-active;
    .arrowWrapper {
      color: $color-menu-active;
    }
  }
  &Box {
    display: block;
    width: 2.2rem;
    height: 2.2rem;
    margin: 0 auto;
    position: relative;
  }
  &Arrow {
    position: absolute;
    top: 50%;
    left: 100%;
    margin-left: 0.4rem;
    font-size: 1.8rem !important;
    transform: translateY(-50%);
  }
}
.arrow {
  transform: scaleY(1);
  transition: 0.3s;
  font-size: 2rem;
  &Open {
    transform: scaleY(-1);
  }
}
