@use 'src/styles/config' as *;

.root {
  position: absolute;
  top: 6rem;
  left: 6rem;
  right: 6rem;
}

.logo {
  img {
    max-width: 100%;
    display: block;
    height: 6.4rem;
  }
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    top: 3rem;
    left: 3rem;
    right: 3rem;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .root {
    top: 1.6rem;
    left: 1.6rem;
  }
  .logo {
    img {
      height: 4.4rem;
    }
  }
}
