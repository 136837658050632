//main navigation
$color-nav-bg: #f6f8fa;

$color-tertiary: #e76f51;
$color-tertiary-light: rgba($color-tertiary, 0.5);

$color-grey: #f6f8fa;

$color-default: #434343;
$color-table: #888d8f;
$color-grey-dark: #546e7b;
$color-grey-light: #f6f8f8;

//new
$color-primary: #1f8ceb;
$color-primary-dark: #1b55a5;
$color-primary-light: rgba($color-primary, 0.5);

$color-secondary: #556066;
$color-secondary-light: rgba($color-secondary, 0.5);

$color-text-primary: #010002;
$color-text-secondary: #556066;
$color-text-light: #848e94;

$color-line: #e1e7ef;
$color-page-background: #fff;

$color-white: #fff;
$color-black: #000;

$color-divider: #f0f0f0;
$color-border: #f0f0f0;
$color-background: #f6f8fa;

$color-error: #ee6c4d;
$color-success: #5bd598;
$color-warning: #fdb502;
$color-info: #556066;
$color-info-light: #848e94;

$color-grey-button: #f5f5f5;
$color-grey-light-2: #f5f6f7;

$color-silver: #bfbfbf;

$color-menu-active: #2065d1;
