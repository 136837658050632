@use 'src/styles/config' as *;

.root {
  display: grid;
  grid-template-columns: $panel-width 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: 'panel content';
  flex-grow: 1;
  & > * {
    min-width: 0;
  }
}
.panel {
  grid-area: panel;
}
.content {
  padding: 0 $content-spacing 1rem;
  grid-area: content;
}

@media (max-width: map-get($breakpoints, 'lg') - 1 + px) {
  .root {
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
  }
  .content {
    padding-left: $content-spacing-mobile;
    padding-right: $content-spacing-mobile;
  }
}

@media (max-width: map-get($breakpoints, 'md') - 1 + px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }
}
